import React, { useState, useEffect } from 'react'
import './ServicePage.css'
export default function ServicePage() {
  const [services, setServices] = useState([])

  useEffect(() => {
    fetch('./services.json')
      .then((res) => res.json())
      .then((data) => {
        setServices(data)
      })

    return () => {
      setServices([])
    }
  }, [])

  return (
    <div className='container mt-banner pb-3' style={{ minHeight: '100vh' }}>
      <h1 className='text-center mb-5'> Our Services</h1>
      <div className='row row-cols-1 row-cols-md-2 g-2'>
        {services.map((service) =>
          service.id % 2 === 0 ? (
            <React.Fragment key={service.id}>
              <div
                id={`service-${service.id}`}
                className='col d-flex flex-column justify-content-center px-5'
                style={{ textAlign: 'justify' }}>
                <h3 className='text-center pb-2'>{service.heading}</h3>
                <p>{service.content}</p>
              </div>
              <div className='d-none d-md-flex col d-flex justify-content-center'>
                <img src={service.image} className='img-fluid rounded' alt='' />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment key={service.id}>
              <div className='d-none d-md-flex col d-flex justify-content-center'>
                <img src={service.image} className='img-fluid rounded' alt='' />
              </div>
              <div
                id={`service-${service.id}`}
                className='col d-flex flex-column justify-content-center  px-5'
                style={{ textAlign: 'justify' }}>
                <h3 className='text-center pb-2'>{service.heading}</h3>
                <p>{service.content}</p>
              </div>
            </React.Fragment>
          )
        )}
      </div>
    </div>
  )
}
