import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./Services.css"

const Services = () => {
  const [services, setServices] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    fetch("./services.json")
      .then((res) => res.json())
      .then((data) => {
        setServices(data)
      })

    return () => {
      setServices([])
    }
  }, [])

  const redirectToServices = (id) => {
    navigate(`/services#service-${id}`)
  }

  return (
    <div id="services" className="services-container py-5">
      <div className="container ">
        <h1 className="text-center mb-4">Services</h1>
        <div className="service-item-grid pb-3">
          {services.map((service) => (
            <>
              <div
                className="service-item"
                key={service.id}
                onClick={() => {
                  redirectToServices(service.id)
                }}>
                <div className="service-icon-container" style={{ background: `${service.iconBg}`, color: `${service.iconBg}` }}>
                  <img src={service.icon} className="service-icon" alt="" />
                </div>
                <div className="ms-2 service-title">{service.heading}</div>
              </div>
              {/* {[2, 4].includes(service.id) && <div className="d-none d-lg-block"></div>} */}
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Services
