import React, { useRef } from 'react'
import CountUp from 'react-countup'
import './Stats.css'
import { useIsVisible } from 'react-is-visible'

const Stats = () => {
  const countRef = useRef()
  const isVisible = useIsVisible(countRef, { once: true })
  return (
    <div className='stats'>
      <div className='container mt-1 mt-lg-0'>
        <div ref={countRef}>
          {isVisible && (
            <div className='row row-cols-2 row-cols-sm-4 gy-3'>
              <div className='col'>
                <p>
                  <span className='stat-score'>
                    <CountUp start={0} end={500} duration={4} useEasing={true}></CountUp>
                    {'+'}
                  </span>
                  <br /> Influencers
                </p>
              </div>
              <div className='col'>
                <p>
                  <span className='stat-score'>
                    <CountUp start={0} end={25} duration={4} useEasing={true}></CountUp>
                    {'+'}
                  </span>
                  <br /> Brands
                </p>
              </div>
              <div className='col'>
                <p>
                  <span className='stat-score'>
                    <CountUp start={0} end={50} duration={4} useEasing={true}></CountUp> M.+
                  </span>
                  <br />
                  Reach
                </p>
              </div>
              <div className='col'>
                <p>
                  <span className='stat-score'>
                    x<CountUp start={0} end={10} duration={4} useEasing={true}></CountUp>{' '}
                  </span>
                  <br /> Engagement
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Stats
