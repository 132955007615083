import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../Influencers.css'

const InfluencersMD = (props) => {
  const images = props.images

  const navigate = useNavigate()
  const redirectToRoute = () => {
    navigate('/influencers')
  }

  return (
    <div className='inf-grid'>
      <img src={images[0]} className='img-fluid' alt='' />
      <img src={images[1]} className='img-fluid' alt='' />
      <img src={images[4]} className='img-fluid' alt='' />
      <img src={images[36]} className='img-fluid' alt='' />
      <img src={images[37]} className='img-fluid' alt='' />
      <img src={images[30]} className='img-fluid' alt='' />
      <img src={images[33]} className='img-fluid' alt='' />
      <img src={images[22]} className='img-fluid' alt='' />
      <img src={images[7]} className='img-fluid' alt='' />
      {/* inf */}
      <div className='btn-inf' role={'button'}>
        <span
          onClick={() => {
            redirectToRoute()
          }}>
          Pick Your <br /> Influencer
        </span>
      </div>
      {/* inf end */}
      <img src={images[38]} className='img-fluid' alt='' />
      <img src={images[39]} className='img-fluid' alt='' />
      <img src={images[40]} className='img-fluid' alt='' />
      <img src={images[41]} className='img-fluid' alt='' />
      <img src={images[42]} className='img-fluid' alt='' />
      <img src={images[14]} className='img-fluid' alt='' />
      <img src={images[11]} className='img-fluid' alt='' />
      <img src={images[35]} className='img-fluid' alt='' />
      <img src={images[43]} className='img-fluid' alt='' />
    </div>
  )
}

export default InfluencersMD
