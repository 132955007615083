import React from 'react'
import './FloatingSocial.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

const FloatingSocial = () => {
  return (
    <div className='floating-social-container'>
      <a href='https://facebook.com/Influencerhub.bd' target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon className='floating-social' icon={faFacebook} />
      </a>
      <a
        href='https://instagram.com/officialinfluencerhub'
        target='_blank'
        rel='noopener noreferrer'>
        <FontAwesomeIcon className='floating-social' icon={faInstagram} />
      </a>
      <a
        href='https://www.linkedin.com/company/influencerhub-bd/'
        target='_blank'
        rel='noopener noreferrer'>
        <FontAwesomeIcon className='floating-social' icon={faLinkedin} />
      </a>
    </div>
  )
}

export default FloatingSocial
