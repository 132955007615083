import './BriefUs.css'
import React from 'react'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'

const BriefUs = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  const prepData = (data) => {
    data.objective = data.objective.map((index) => index.value)
    data.profile = data.profile.map((index) => index.value)
    data.platform = data.platform.map((index) => index.value)
    data.posts = data.posts.map((index) => index.value)
    data.audience = data.audience.map((index) => index.value)
    data.influencerType = data.influencerType.map((index) => index.value)
    data.country = data.country.map((index) => index.value)
    data.budget = data.budget.value
    data.duration = data.duration.value

    return data
  }
  const onSubmit = (data) => {
    data = prepData(data)
    console.log(data)
    axios.post('https://young-hollows-72882.herokuapp.com/brands', data).then(reset())
  }

  return (
    <div className='brief-us'>
      <h1 className='container pt-3'>Brief Us</h1>
      <form className='pb-5 brief-us-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='container pt-3'>
          <div className='form-row'>
            <div className='inp-container'>
              <input
                type='text'
                placeholder='Brand Name'
                {...register('brandName', {
                  required: 'This field must not be left empty.',
                })}
              />
              {errors.brandName && <p className='text-danger'>{errors.brandName.message}</p>}
            </div>

            <div className='inp-container'>
              {/* obj */}
              <Controller
                name='objective'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Campaign Objective (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Awareness', label: 'Awareness' },
                      { value: 'Conversion', label: 'Conversion' },
                      { value: 'Engagement', label: 'Engagement' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.objective && <p className='text-danger'>{errors.objective.message}</p>}
            </div>
          </div>

          <div className='form-row'>
            <div className='inp-container'>
              {/* profile */}
              <Controller
                name='profile'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Influencer Profile (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Fashion', label: 'Fashion' },
                      { value: 'Lifestyle', label: 'Lifestyle' },
                      { value: 'Beauty', label: 'Beauty' },
                      { value: 'Foods', label: 'Foods' },
                      { value: 'Sports', label: 'Sports' },
                      { value: 'Gaming', label: 'Gaming' },
                      { value: 'Business', label: 'Business' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.profile && <p className='text-danger'>{errors.profile.message}</p>}
            </div>

            <div className='inp-container'>
              {/* posts */}
              <Controller
                name='posts'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Type of posts (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Video Post', label: 'Video Post' },
                      { value: 'Image', label: 'Image' },
                      { value: 'Stories', label: 'Stories' },
                      { value: 'Store Visit', label: 'Store Visit' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.posts && <p className='text-danger'>{errors.posts.message}</p>}
            </div>
          </div>

          <div className='form-row'>
            <div className='inp-container'>
              <input
                type='text'
                placeholder='Campaign Message'
                {...register('message', {
                  required: 'This field must not be left empty.',
                })}
              />
              {errors.message && <p className='text-danger'>{errors.message.message}</p>}
            </div>

            <div className='inp-container'>
              {/* audience */}
              <Controller
                name='audience'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Target Audience (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'Gen Z', label: 'Gen Z' },
                      { value: 'Millenials', label: 'Millenials' },
                      { value: 'Gen X', label: 'Gen X' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.audience && <p className='text-danger'>{errors.audience.message}</p>}
            </div>
          </div>

          <div className='form-row'>
            <div className='inp-container'>
              {/* country */}
              <Controller
                name='country'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Target Country (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'UAE', label: 'UAE' },
                      { value: 'KSA', label: 'KSA' },
                      { value: 'Kuwait', label: 'Kuwait' },
                      { value: 'GCC', label: 'GCC' },
                      { value: 'International', label: 'International' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.country && <p className='text-danger'>{errors.country.message}</p>}
            </div>

            <div className='inp-container'>
              {/* social platforms */}
              <Controller
                name='platform'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Target Platform (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Instagram', label: 'Instagram' },
                      { value: 'Tiktok', label: 'Tiktok' },
                      { value: 'Snapchat', label: 'Snapchat' },
                      { value: 'YouTube', label: 'YouTube' },
                      { value: 'Twitter', label: 'Twitter' },
                      { value: 'Twitch', label: 'Twitch' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.platform && <p className='text-danger'>{errors.platform.message}</p>}
            </div>
          </div>

          <div className='form-row'>
            <div className='inp-container'>
              {/* duration */}
              <Controller
                name='duration'
                control={control}
                rules={{ required: 'One option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    placeholder='Duration of campaign (Please select one from below)'
                    {...field}
                    options={[
                      { value: '1months', label: '1 months or less' },
                      { value: '3months', label: '3 months' },
                      { value: '6months', label: '6 months' },
                      { value: 'Yearly', label: 'Yearly' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.duration && <p className='text-danger'>{errors.duration.message}</p>}
            </div>
            <div className='inp-container'>
              {/* budget options */}
              <Controller
                name='budget'
                control={control}
                rules={{ required: 'One option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    placeholder='Budget options (Please select one from below)'
                    {...field}
                    options={[
                      { value: '5k', label: '$5,000' },
                      { value: '10k', label: '$10,000' },
                      { value: '15k', label: '$15,000' },
                      { value: '20k', label: '$20,000' },
                      { value: '30k', label: '$30,000' },
                      { value: '40k', label: '$40,000' },
                      { value: '50k+', label: '$50,000 or above' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.duration && <p className='text-danger'>{errors.duration.message}</p>}
            </div>
          </div>

          <div className='form-row'>
            <div className='inp-container'>
              {/* Type of influencers */}
              <Controller
                name='influencerType'
                control={control}
                rules={{ required: 'Atleast one option has to be selected.' }}
                render={({ field }) => (
                  <Select
                    isMulti
                    placeholder='Type of Influencers (Please select one or more from below)'
                    {...field}
                    options={[
                      { value: 'Micro', label: 'Micro' },
                      { value: 'Macro', label: 'Macro' },
                      { value: 'Mega', label: 'Mega' },
                      { value: 'Celebrity', label: 'Celebrity' },
                    ]}
                    className='basic-multi-select'
                  />
                )}
              />
              {errors.influencerType && (
                <p className='text-danger'>{errors.influencerType.message}</p>
              )}
            </div>

            <div className='inp-container'>
              <input
                type='text'
                name=''
                placeholder='Your Name'
                {...register('name', {
                  required: 'This field must not be left empty.',
                })}
              />
              {errors.name && <p className='text-danger'>{errors.name.message}</p>}
            </div>
          </div>
          <div className='form-row'>
            <div className='inp-container'>
              <input
                type='email'
                name=''
                placeholder='Email'
                {...register('email', {
                  required: 'This field must not be left empty.',
                })}
              />
              {errors.email && <p className='text-danger'>{errors.email.message}</p>}
            </div>
            <div className='inp-container'>
              <input
                type='text'
                name=''
                placeholder='Phone Number'
                {...register('contact', {
                  required: 'This field must not be left empty.',
                })}
              />
              {errors.contact && <p className='text-danger'>{errors.contact.message}</p>}
            </div>
          </div>
          <div className='d-flex flex-column w-100 justify-content-center align-items-center'>
            <textarea
              rows='2'
              placeholder='Any other Information'
              {...register('other')}></textarea>

            <button className='btn-generic'>Submit</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BriefUs
