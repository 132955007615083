import React from "react"
import "./Loading.css"

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-bar"></div>
      <div className="loading-bar-2"></div>
    </div>
  )
}

export default Loading
