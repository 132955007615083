import React from 'react'
import { useNavigate } from 'react-router-dom'

const InfluencersSM = (props) => {
  const images = props.images
  const navigate = useNavigate()

  const redirectToRoute = () => {
    navigate('/influencers')
  }

  return (
    <div className='inf-grid-sm'>
      <img src={images[0]} className='img-fluid' alt='' />
      <img src={images[1]} className='img-fluid' alt='' />
      <img src={images[42]} className='img-fluid' alt='' />
      <img src={images[13]} className='img-fluid' alt='' />
      <img src={images[14]} className='img-fluid' alt='' />
      <img src={images[30]} className='img-fluid' alt='' />
      {/* inf */}
      <div className='btn-inf'>
        <span
          onClick={() => {
            redirectToRoute()
          }}>
          Pick Your <br /> Influencer
        </span>
      </div>
      {/* inf end */}
      <img src={images[36]} className='img-fluid' alt='' />
      <img src={images[12]} className='img-fluid' alt='' />
      <img src={images[8]} className='img-fluid' alt='' />
      <img src={images[41]} className='img-fluid' alt='' />
      <img src={images[22]} className='img-fluid' alt='' />
      <img src={images[33]} className='img-fluid' alt='' />
      {/* <img src={images[32]} className="img-fluid" alt="" /> */}
    </div>
  )
}

export default InfluencersSM
