import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

const SubRegister = () => {
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    fetch("https://young-hollows-72882.herokuapp.com/register")
      .then((res) => res.json())
      .then((data) => setEntities(data));
  }, []);

  console.log(entities);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "75%",
      sm: "75%",
      md: "50%",
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: 15,
    p: 4,
  };

  return (
    <div>
      <h1 className="text-center">Registered Customers</h1>
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Company</th>
              <th scope="col">Package</th>
              <th scope="col">View Details</th>
              {/* <th scope="col">Contact</th> */}
              {/* <th scope="col">Email</th> */}
            </tr>
          </thead>
          <tbody>
            {entities.map((e) => (
              <tr key={e.name}>
                <td>{e.name}</td>
                <td>{e.company}</td>
                <td>{e.package}</td>
                <td>
                  <button onClick={handleOpen}>Details</button>
                </td>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div>
                      <p>Name : {e.name}</p>
                      <p>Company : {e.company}</p>
                      <p>Contact : {e.contact}</p>
                      <p>Email : {e.email}</p>
                      <p>Package : {e.package}</p>
                    </div>
                  </Box>
                </Modal>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubRegister;
