import "./HomePage.css"

import React from "react"
import Banner from "../Banner/Banner"
import About from "../About/About"
import Services from "../Services/Services"
import Stats from "../Stats/Stats"
import Influencers from "../Influencers/Influencers"
import Clients from "../Clients/Clients"
import CaseStudy from "../CaseStudy/CaseStudy"
import Blogs from "../Blogs/Blogs"
import Contact from "../Contact/Contact"

const HomePage = () => {
  return (
    <div>
      <Banner />
      {/* <InfluencerAnalysis /> */}
      <About />
      <Services />
      <Stats />
      <Influencers />
      {/* <App /> */}
      {/* <Stars /> */}
      <Clients />
      <CaseStudy />
      <Blogs />
      <div id="contact">
        <Contact />
      </div>
    </div>
  )
}

export default HomePage
