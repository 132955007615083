import React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Outlet, useNavigate } from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import "./Dashboard.css"
import { NavLink } from "react-router-dom"

const drawerWidth = 240

const Dashboard = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  //   let { path, url } = useMatch();
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const redirectToHomePage = () => {
    navigate("/")
  }

  const drawer = (
    <div className="dashboard-drawer">
      <Toolbar />
      <NavLink to="/dashboard" className="side-nav-common">
        <h5 className="d-flex justify-content-center">Dashboard</h5>
      </NavLink>
      <Divider />
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to={"/dashboard/register"}>Registered</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={"/dashboard/brands"}>Brands</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={"/dashboard/influencers"}>Influencers</NavLink>
        </li>
      </ul>
    </div>
  )
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "darkslategray",
        }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
          <div className="d-flex align-items-center">
            <div className="back-btn me-2">
              <ArrowBackIosIcon onClick={() => redirectToHomePage()}></ArrowBackIosIcon>
            </div>
            <Typography variant="h6" noWrap component="div">
              Admin Dashboard
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "white",
              background: "crimson",
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "white",
              background: "crimson",
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <Toolbar />
        {/* content */}
        <div className="container">
          <Outlet />
        </div>
      </Box>
    </Box>
  )
}

export default Dashboard
