import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import './App.css'
import BlogPage from './Pages/BlogPage/BlogPage/BlogPage'
import BriefUs from './Pages/BriefUs/BriefUs'
import HomePage from './Pages/HomePage/HomePage/HomePage'
import InfluencerRegister from './Pages/InfluencerRegister/InfluencerRegister'
import NotFound from './Pages/NotFound/NotFound'
import Register from './Pages/Register/Register'
import Footer from './Shared/Footer/Footer'
import Header from './Shared/Header/Header'
import Loading from './Shared/Loading/Loading'
import Dashboard from './Pages/Dashboard/Dashboard'
import Brands from './Pages/Dashboard/Subroutes/Brands/Brands'
import SubRegister from './Pages/Dashboard/Subroutes/SubRegister/SubRegister'
import { useState } from 'react'
import SubInfluencers from './Pages/Dashboard/Subroutes/SubInfluencers/SubInfluencers'
import InfluencerPage from './Pages/InfluencerPage/InfluencerPage'
import CampaignsPage from './Pages/CampaignsPage/CampaignsPage'
import ServicePage from './Pages/ServicePage/ServicePage'
import FloatingSocial from './Shared/FloatingSocial/FloatingSocial'
import ClientsPage from './Pages/ClientsPage/ClientsPage'

function App() {
  const [path, setPath] = useState('')
  const Wrapper = ({ children }) => {
    const location = useLocation()
    useEffect(() => {
      // console.log(location.hash.slice(1))
      if (location.hash === '') {
        document.documentElement.scrollTo(0, 0)
        setPath(location.pathname)
      } else {
        setTimeout(() => {
          let element = document.getElementById(location.hash.slice(1))
          // console.log(element)
          element.scrollIntoView()
        }, 500)
      }
      return () => {
        setPath('')
      }
    }, [location.pathname, location.hash])
    return children
  }

  const [loaded, setLoaded] = useState(false)
  document.onreadystatechange = () => {
    setLoaded(document.readyState === 'complete')
  }

  const [loginStatus, setLoginStatus] = useState(false)

  return loaded ? (
    <div className='App'>
      <BrowserRouter>
        <Wrapper>
          {!path.startsWith('/dashboard') && (
            <>
              <Header loginStatus={loginStatus} setLoginStatus={setLoginStatus} />
              <FloatingSocial />
            </>
          )}

          <Routes>
            <Route path='/' element={<HomePage />}></Route>
            <Route path='/home' element={<HomePage />}></Route>
            <Route path='/blogs' element={<BlogPage />}></Route>
            <Route path='/influencers' element={<InfluencerPage />}></Route>
            <Route path='/brands' element={<ClientsPage />}></Route>
            <Route path='/campaigns' element={<CampaignsPage />}></Route>
            <Route path='/services' element={<ServicePage />}></Route>
            {loginStatus && (
              <Route path='/dashboard' element={<Dashboard />}>
                <Route path='' element={<SubRegister />} />
                <Route path='register' element={<SubRegister />} />
                <Route path='brands' element={<Brands />} />
                <Route path='influencers' element={<SubInfluencers />} />
              </Route>
            )}
            <Route path='/register' element={<Register />}></Route>
            <Route path='/influencer-register' element={<InfluencerRegister />}></Route>
            <Route path='/brief-us' element={<BriefUs />}></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Routes>
          {!path.startsWith('/dashboard') && <Footer />}
        </Wrapper>
      </BrowserRouter>
    </div>
  ) : (
    <Loading />
  )
}

export default App
