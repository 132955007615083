import React, { useEffect, useState } from "react"
import "./BlogPage.css"
import { useLocation } from "react-router-dom"

const BlogPage = () => {
  const [blog, setBlog] = useState({})
  const location = useLocation()
  // get userId
  let blogId = location.state.blogId

  useEffect(() => {
    fetch("./blogs.json")
      .then((res) => res.json())
      .then((data) => {
        let b = data.filter((d) => d.id === blogId)[0]
        setBlog(b)
        // console.log(b.content.rep)
      })
  }, [blogId])
  return (
    <div className="mt-banner container">
      <h1 className="text-center"> {blog.title}</h1>
      <div className="blog-grid">
        <div className="blog-img-container">
          <h5 className="text-center">{blog.date}</h5>
          <img className="img-fluid" src={blog.image} alt="" />
        </div>
        <div className="blog-text-container">
          <p>{blog.content}</p>
        </div>
      </div>
    </div>
  )
}

export default BlogPage
