import React, { useState, useEffect } from 'react'
import Loading from '../../Shared/Loading/Loading'
import './ClientsPage.css'

export default function ClientsPage() {
  const [brandLogos, setBrandLogos] = useState([])
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    fetch('./brandsLogo.json')
      .then((res) => res.json())
      .then((data) => {
        setBrandLogos(data)
        setLoaded(true)
      })

    return () => {
      setBrandLogos([])
      setLoaded(false)
    }
  }, [])

  return (
    <div className='mt-banner container' style={{ minHeight: '100vh' }}>
      <h1 className='text-center mt-2'>Clients that we worked with</h1>
      {loaded ? (
        <div className='mb-5 row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-3'>
          {brandLogos.map((logo) => (
            <div className='col d-flex align-items-center justify-content-center' key={logo.id}>
              <img src={logo.url} alt='' className='img-fluid' />
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}
