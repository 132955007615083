import React from "react"

import "./Contact.css"

const Contact = () => {
  return (
    <div className="contact py-5">
      <div className="container">
        <h1>Contact Us</h1>
        <div className="px-0 px-md-3 py-1">
          <form>
            <input type="text" name="Name" placeholder="Name" id="name" />
            <br />
            <input type="email" name="Email" placeholder="Email" id="email" />
            <br />
            <input type="text" name="Message" placeholder="Message" id="message" />
          </form>
          <div className="d-flex justify-content-center ">
            <button className="btn-contact">Send Message</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
