import React, { useState, useEffect } from 'react'
import './CampaignsPage.css'

const CampaignsPage = () => {
  const [campaigns, setCampaigns] = useState([])
  useEffect(() => {
    fetch('./caseStudy.json')
      .then((res) => res.json())
      .then((data) => {
        setCampaigns(data)
      })

    return () => {
      setCampaigns([])
    }
  }, [])
  return (
    <div className='mt-banner'>
      <div className='container' style={{ minHeight: '100vh' }}>
        <h1 className='text-center mb-4'>Don't take our word for it</h1>
        <div className='d-flex flex-column'>
          {campaigns.map((c) => (
            <div key={c.id} className='caseStudy-container'>
              <div>
                <img src={c.imgUrl} alt='' />
              </div>
              <div className='campaign-details'>
                <p className='client'>Client : {c.client}</p>
                <p className='objective'>Objective : {c.object}</p>
                <p className='strategy'>Our Strategy : {c.strategy}</p>
                <p>Results :</p>
                <p className='results'>
                  Post React : {c.result.postReact} <br />
                  Post Views : {c.result.postViews} <br />
                  Post Engagement : {c.result.postEngagement}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CampaignsPage
