import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './Clients.css'
import { clientImages } from '../../../utils/Images'
import { useNavigate } from 'react-router-dom'

const Clients = () => {
  const navigate = useNavigate()
  const redirectToRoute = () => {
    navigate('/brands')
  }

  return (
    <div id='clients' className='text-start overflow-hidden py-5 clients-container'>
      <div className='container'>
        <h1 className='mb-3 text-center'> Our Clients </h1>
        <div className='row row-cols-2 row-cols-md-3 row-cols-lg-6 py-5 gy-5'>
          {clientImages.map((image) => (
            <div className='col d-flex justify-content-center' key={image}>
              <img className='clients-img' src={image} alt='' />
            </div>
          ))}
        </div>
        <div className='d-flex justify-content-center'>
          <button
            style={{ background: '#FFE0E3' }}
            className='btn-generic'
            onClick={() => {
              redirectToRoute()
            }}>
            See Our Clients
          </button>
        </div>
      </div>
    </div>
  )
}

export default Clients
