// banner images
// import banner1 from './banner-img/1.png';
// import banner2 from './banner-img/2.jpg';
// import banner3 from './banner-img/3.jpg';
// import banner4 from './banner-img/4.jpg';
// client images
import client1 from './clients-img/client-1.png'
import client2 from './clients-img/client-6.png'
import client3 from './clients-img/client-5.png'
import client4 from './clients-img/client-3.png'
import client5 from './clients-img/client-2.png'
import client6 from './clients-img/client-4.png'
// case study images
import caseStudy1 from './case-study/xpel.jpg'
import caseStudy2 from './case-study/sepnil.jpg'
import caseStudy3 from './case-study/meril.jpg'
import caseStudy4 from './case-study/kool.jpg'
import caseStudy5 from './case-study/square.jpg'
// influencer images
import inf1 from './influencers/1.jpg'
import inf2 from './influencers/2.jpg'
import inf3 from './influencers/3.jpg'
import inf4 from './influencers/4.jpg'
import inf5 from './influencers/5.jpg'
import inf6 from './influencers/6.jpg'
import inf7 from './influencers/7.jpg'
import inf8 from './influencers/8.jpg'
import inf9 from './influencers/9.jpg'
import inf10 from './influencers/10.jpg'
import inf11 from './influencers/11.jpg'
import inf12 from './influencers/12.jpg'
import inf13 from './influencers/13.jpg'
import inf14 from './influencers/14.jpg'
import inf15 from './influencers/15.jpg'
import inf16 from './influencers/16.jpg'
import inf17 from './influencers/17.jpg'
import inf18 from './influencers/18.jpg'
import inf19 from './influencers/19.jpg'
import inf20 from './influencers/20.jpg'
import inf21 from './influencers/21.jpg'
import inf22 from './influencers/22.jpg'
import inf23 from './influencers/23.jpg'
import inf24 from './influencers/24.jpg'
import inf25 from './influencers/25.jpg'
import inf26 from './influencers/26.jpg'
import inf27 from './influencers/27.jpg'
import inf28 from './influencers/28.jpg'
import inf29 from './influencers/29.jpg'
import inf30 from './influencers/30.jpg'
import inf31 from './influencers/31.jpg'
import inf32 from './influencers/32.jpg'
import inf33 from './influencers/33.jpg'
import inf34 from './influencers/34.jpg'
import inf35 from './influencers/35.jpg'
import inf36 from './influencers/36.png'
import inf37 from './influencers/37.png'
import inf38 from './influencers/38.png'
import inf39 from './influencers/39.png'
import inf40 from './influencers/40.png'
import inf41 from './influencers/41.png'
import inf42 from './influencers/42.png'
import inf43 from './influencers/43.png'
import inf44 from './influencers/44.png'

const clientImages = [client1, client2, client3, client4, client5, client6]

const caseStudyImages = [caseStudy1, caseStudy2, caseStudy3, caseStudy4, caseStudy5]

const infImages = [
  inf1,
  inf2,
  inf3,
  inf4,
  inf5,
  inf6,
  inf7,
  inf8,
  inf9,
  inf10,
  inf11,
  inf12,
  inf13,
  inf14,
  inf15,
  inf16,
  inf17,
  inf18,
  inf19,
  inf20,
  inf21,
  inf22,
  inf23,
  inf24,
  inf25,
  inf26,
  inf27,
  inf28,
  inf29,
  inf30,
  inf31,
  inf32,
  inf33,
  inf34,
  inf35,
  inf36,
  inf37,
  inf38,
  inf39,
  inf40,
  inf41,
  inf42,
  inf43,
  inf44,
]

export { clientImages, caseStudyImages, infImages }
