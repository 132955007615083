import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Blogs.css'

const Blogs = () => {
  const [blogs, setBlogs] = useState([])
  const navigate = useNavigate()

  const redirectToBlog = (id) => {
    navigate('/blogs', {
      state: {
        blogId: id,
      },
    })
  }

  useEffect(() => {
    fetch('./blogs.json')
      .then((res) => res.json())
      .then((data) => setBlogs(data))

    return () => {
      setBlogs([])
    }
  }, [])

  return (
    <div id='blogs'>
      <div className='blogs d-flex flex-column align-items-center justify-content-center py-3 py-5'>
        <div className='container'>
          <h1 className='text-center pt-3 mb-4'>Blog Posts</h1>
        </div>
        <div className='container row row-cols-1 g-3 '>
          {blogs.map((blog) => (
            <div className='blog-container' key={blog.id}>
              <div className='blog-img-container'>
                <img className='blog-img img-fluid' src={blog.image} alt='' />
              </div>
              <div className='blog-details'>
                <p className='blog-date'>{blog.date}</p>
                <p className='blog-title'>{blog.title}</p>
                <p className='blog-desc'>{blog.shortDesc}</p>
                <button
                  className='btn-generic'
                  onClick={() => {
                    redirectToBlog(blog.id)
                  }}>
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="container text-end mt-4 w-100">
          <h5>Go to the Blogs Page</h5>
        </div> */}
      </div>
    </div>
  )
}

export default Blogs
