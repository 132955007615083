import React from 'react'
import './Banner.css'
import Slider from 'react-slick'

import { useNavigate } from 'react-router-dom'

const Banner = () => {
  const navigate = useNavigate()

  // slider settings
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className='mt-banner'>
      <Slider {...settings}>
        {/* slide 1 */}
        <div className='banner banner-1'>
          <div className='banner-text'>
            <p className='banner-heading'>
              Propelling Brands <br /> Through Powerful People
            </p>
            <div className='banner-btn-container'>
              <button
                className='banner-btn'
                onClick={() => {
                  navigate('/brief-us')
                }}>
                {' '}
                Brand Brief Us
              </button>
              <button
                className='banner-btn'
                onClick={() => {
                  navigate('/influencer-register')
                }}>
                {' '}
                Influencer Register Now
              </button>
            </div>
            {/* <div className="top-clients-container">
          <div className="top-clients py-2">
            <div>
              <p className="client-item">a</p>
              <p className="client-item">b</p>
              <p className="client-item">c</p>
              <p className="client-item">d</p>
              <p className="client-item">e</p>
            </div>
            <div>
              <p className="client-item">a</p>
              <p className="client-item">b</p>
              <p className="client-item">c</p>
              <p className="client-item">d</p>
              <p className="client-item">e</p>
            </div>
          </div>
        </div> */}
          </div>
        </div>
        {/* slide 2 */}
        <div className='banner banner-2'>
          <div className='banner-text'>
            <p className='banner-heading'>
              Country's Largest <br /> Data-Driven Influencer Marketing Agency
            </p>
            <div className='banner-btn-container'>
              <button
                className='banner-btn'
                onClick={() => {
                  navigate('/campaigns')
                }}>
                {' '}
                See the Campaigns
              </button>
            </div>
          </div>
        </div>
        {/* slide 3 */}
        <div className='banner banner-3'>
          <div className='banner-text'>
            <p className='banner-heading'>
              Closely measure your campaign performance and ROI <br /> based on real-time statistics
            </p>
            <div className='banner-btn-container'>
              <button
                className='banner-btn'
                onClick={() => {
                  navigate('/influencers')
                }}>
                {' '}
                See the Influencers
              </button>
            </div>
          </div>
        </div>
        {/* slide 4 */}
        {/* <div className='banner banner-4'>
          <div className='banner-text'>
            <p className='banner-heading'>Take a look at what Brands have to say about us</p>
            <div>
              <button
                className='me-5 banner-btn'
                onClick={() => {
                  navigate('/brands')
                }}>
                {' '}
                Meet Our Clients
              </button>
            </div>
          </div>
        </div> */}
      </Slider>
    </div>
  )
}

export default Banner
