import React, { useState, useEffect } from 'react'
import './InfluencerPage.css'
import Slider from 'react-slick'
import Loading from '../../Shared/Loading/Loading'

export default function InfluencerPage() {
  const [influencers, setInfluencers] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [type, setType] = useState('Nano')

  const types = ['Nano', 'Micro', 'MidTier', 'Macro', 'Mega', 'Celebrity']

  useEffect(() => {
    fetch('./influencers.json')
      .then((res) => res.json())
      .then((data) => {
        const filteredInfluencers = data.find((d) => d.typeName === type)
        setInfluencers(filteredInfluencers)
        setLoaded(true)
      })

    return () => {
      setInfluencers([])
      setLoaded(false)
    }
  }, [type])

  // slick slider settings
  var sliderSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 492,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  }

  return (
    <div className='container mt-banner'>
      {/* grid for md , slider for sm */}
      <h1 className='text-center inf-page-heading'>Meet the Influencers!</h1>
      {/* select a type */}
      <div className='inf-type-grid'>
        {types.map((t) => (
          <span
            key={t}
            className={t === type ? 'inf-type inf-type-active' : 'inf-type'}
            onClick={() => {
              setType(t)
            }}>
            {t}
          </span>
        ))}
      </div>

      {/* main */}
      <div>
        {/* display heading and definition */}
        <h2> {type} Influencers </h2>
        <p className='inf-type-def'> {influencers.typeDef}</p>
        {/* for devices over md screen */}
        {loaded ? (
          <div className='d-none d-md-grid inf-details-grid'>
            {influencers.infList !== undefined &&
              influencers.infList.map((inf) => (
                <div className='inf-details' key={inf.id}>
                  <a className='inf-link' href={inf.infLink}>
                    <img src={inf.infImg} className='img-fluid' alt={inf.infName} />
                    <div className='inf-text-absolute'>
                      <p className='inf-name'>{inf.infName}</p>
                      <p>{inf?.infCategory || 'Content Creator'}</p>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        ) : (
          <Loading />
        )}

        {/* for mobile devices */}
        <div className='d-md-none my-3'>
          <Slider {...sliderSettings}>
            {influencers.infList !== undefined &&
              influencers.infList.map((inf) => (
                <div className='inf-details' key={inf.id}>
                  <a className='inf-link' href={inf.infLink}>
                    <img src={inf.infImg} className='img-fluid' alt={inf.infName} />
                    <div className='inf-text-absolute'>
                      <p className='inf-name'>{inf.infName}</p>
                      <p>{inf?.infCategory || 'Content Creator'}</p>
                    </div>
                  </a>
                </div>
              ))}
          </Slider>
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <p className='and-many-more'> And Many more...</p>
        </div>
      </div>
    </div>
  )
}
