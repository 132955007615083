import React from "react"
import { useLocation } from "react-router-dom"
import { useForm } from "react-hook-form"
import "./Register.css"
import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTiktok, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { state } = useLocation()
  const packageInfo = state

  const createIcon = (iconName) => {
    if (iconName === "faTiktok") {
      return React.createElement(FontAwesomeIcon, {
        icon: faTiktok,
        key: iconName,
      })
    }
    if (iconName === "faYoutube") {
      return React.createElement(FontAwesomeIcon, {
        icon: faYoutube,
        key: iconName,
      })
    }
    if (iconName === "faInstagram") {
      return React.createElement(FontAwesomeIcon, {
        icon: faInstagram,
        key: iconName,
      })
    }
  }

  const onSubmit = (data) => {
    data.package = packageInfo.name
    axios.post("https://young-hollows-72882.herokuapp.com/register", data).then(reset())
  }

  return (
    <div className="register">
      <h1 className="text-center pb-3">Register Here</h1>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 d-flex  align-items-center g-3">
          <div className="col-12 col-md-4 m-0">
            <div className="package">
              <div>
                <h2>
                  {packageInfo.name} <small>PACKAGE</small>
                </h2>
                <div className="package-icons">{packageInfo.social.map((s) => createIcon(s))}</div>
                <p className="price">$ {packageInfo.price}</p>
                <p className="num-reports">{packageInfo.reports} REPORTS</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="Full Name"
                {...register("name", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.name && <p className="text-danger">{errors.name.message}</p>}

              <input
                type="email"
                placeholder="Email Adress"
                {...register("email", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.email && <p className="text-danger">{errors.email.message}</p>}
              <input
                type="text"
                placeholder="Phone Number"
                {...register("contact", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.contact && <p className="text-danger">{errors.contact.message}</p>}
              <input
                type="text"
                placeholder="Company Name"
                {...register("company", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.company && <p className="text-danger">{errors.company.message}</p>}
              <input type="hidden" {...register("package")} />
              <br />
              <div className="d-flex justify-content-start">
                <button className="btn-generic">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
