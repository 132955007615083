import React from 'react'
import { caseStudyImages } from '../../../utils/Images'
import { useNavigate } from 'react-router-dom'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

import './CaseStudy.css'
const CaseStudy = () => {
  const switchAccordion = (e) => {
    const children = document.getElementById('horizontal-accordion').children
    for (let i = 0; i < children.length; i++) {
      children[i].classList.remove('active-item')
    }
    e.target.parentElement.classList.add('active-item')
  }
  const navigate = useNavigate()

  const redirectToCampaigns = () => {
    navigate('/campaigns')
  }

  const switchTab = (e) => {
    const children = document.getElementById('content-sm').children
    const id = e.target.innerText
    const activeChild = document.getElementById(`sm-${id}`)
    for (let i = 0; i < children.length; i++) {
      children[i].classList.add('d-none')
    }
    activeChild.classList.remove('d-none')
    activeChild.classList.add('d-block')
  }

  return (
    <div id='casestudy' className='container py-5'>
      <h1 className='text-center mb-4'>Our Campaigns</h1>
      {/* for tablet and up */}
      <div id='horizontal-accordion' className='d-none d-md-flex'>
        <div className='item active-item'>
          <button
            className='item-number'
            onClick={(e) => {
              switchAccordion(e)
            }}>
            1
          </button>
          <div className='case-study-img'>
            <img src={caseStudyImages[0]} height='400px' alt='' />
          </div>
          <div className='case-study-desc'>
            <h3>Client: Xpel</h3>
            <h5>Objective : Increase awareness about XPEL to become a leading aerosol product.</h5>
            <p>
              <strong>What did we do : </strong>Collaborated with 4 influencers from different
              backgrounds and place Xpel in their regular content in a shuttle way.
            </p>
            <p>
              <strong>Results : </strong>Post Reach - 62%. Post Views - 46%. Post Engagement - 21%
            </p>
          </div>
        </div>
        <div className='item'>
          <button
            className='item-number'
            onClick={(e) => {
              switchAccordion(e)
            }}>
            2
          </button>
          <div className='case-study-img'>
            <img src={caseStudyImages[1]} height='400px' alt='' />
          </div>
          <div className='case-study-desc'>
            <h3>Client : Sepnil</h3>
            <h5>
              Objective : Increase awareness about Sepnil to become a leading sanitizer brand.
            </h5>
            <p>
              <strong>What did we do : </strong>Identified one of the leading influencer “Chanchal
              Chowdhury” to review Sepnil social activities and post videos on Facebook to create
              social awareness
            </p>
            <p>
              <strong>Results : </strong>Post Reach – 72%. Post Views – 52%. Post Engagement – 32%
            </p>
          </div>
        </div>
        <div className='item'>
          <button
            className='item-number'
            onClick={(e) => {
              switchAccordion(e)
            }}>
            3
          </button>
          <div className='case-study-img'>
            <img src={caseStudyImages[2]} height='400px' alt='' />
          </div>
          <div className='case-study-desc'>
            <h3>Client : Meril Petroleum</h3>
            <h5>
              Objective : Increase product presence in the winter market and widen market share.
            </h5>
            <p>
              <strong>What did we do : </strong>Identified 03 top influencers to review Meril
              petroleum and post videos on Facebook &amp; Instagram to expand awareness. Also we
              distributed their own content in various social group.
            </p>
            <p>
              <strong>Results : </strong>Post Reach – 43%. Post Views – 71%. Post Engagement – 29%
            </p>
          </div>
        </div>
        <div className='item'>
          <button
            className='item-number'
            onClick={(e) => {
              switchAccordion(e)
            }}>
            4
          </button>
          <div className='case-study-img'>
            <img src={caseStudyImages[3]} height='400px' alt='' />
          </div>
          <div className='case-study-desc'>
            <h3>Client : KOOL</h3>
            <h5>Objective : Increase awareness about KOOL in a COOL way.</h5>
            <p>
              <strong>What did we do : </strong>Identified 03 top influencers to review KOOL and
              post videos on Facebook &amp; Instagram to expand awareness in a cool way.
            </p>
            <p>
              <strong>Results : </strong>Post Reach – 31%. Post Views – 54%. Post Engagement – 26%
            </p>
          </div>
        </div>
        <div className='item'>
          <button
            className='item-number'
            onClick={(e) => {
              switchAccordion(e)
            }}>
            5
          </button>
          <div className='case-study-img'>
            <img src={caseStudyImages[4]} height='400px' alt='' />
          </div>
          <div className='case-study-desc'>
            <h3>Client : Square Foods &amp; Beverage Limited</h3>
            <h5>
              Objective : Increase brand presence in Bangladeshi market through beautiful
              Bangladesh.
            </h5>
            <p>
              <strong>What did we do : </strong>Identified 6 top influencers to share SFBL #Amar
              Bangladesh content from their social media pages.
            </p>
            <p>
              <strong>Results : </strong>Post Reach – 76%. Post Views – 63%. Post Engagement – 42%
            </p>
          </div>
        </div>
      </div>

      {/*  */}
      {/* for mobile */}
      {/*  */}
      <div className='d-block d-md-none pb-3' id='tab-switch'>
        {/* tabs */}
        <div>
          <button
            className='item-number-sm'
            onClick={(e) => {
              switchTab(e)
            }}>
            1
          </button>
          <button
            className='item-number-sm'
            onClick={(e) => {
              switchTab(e)
            }}>
            2
          </button>
          <button
            className='item-number-sm'
            onClick={(e) => {
              switchTab(e)
            }}>
            3
          </button>
          <button
            className='item-number-sm'
            onClick={(e) => {
              switchTab(e)
            }}>
            4
          </button>
          <button
            className='item-number-sm'
            onClick={(e) => {
              switchTab(e)
            }}>
            5
          </button>
        </div>
        {/* content */}
        <div id='content-sm' className='my-3'>
          <div id='sm-1'>
            <div className='case-study-img-sm'>
              <img src={caseStudyImages[0]} alt='' />
            </div>
            <div className='case-study-desc-sm'>
              <h3>Client: Xpel</h3>
              <h5>
                Objective : Increase awareness about XPEL to become a leading aerosol product.
              </h5>
              <p>
                <strong>What did we do : </strong>Collaborated with 4 influencers from different
                backgrounds and place Xpel in their regular content in a shuttle way.
              </p>
              <p>
                <strong>Results : </strong>Post Reach - 62%. Post Views - 46%. Post Engagement - 21%
              </p>
            </div>
          </div>
          <div id='sm-2' className='d-none'>
            <div className='case-study-img-sm'>
              <img src={caseStudyImages[1]} alt='' />
            </div>
            <div className='case-study-desc-sm'>
              <h3>Client : Sepnil</h3>
              <h5>
                Objective : Increase awareness about Sepnil to become a leading sanitizer brand.
              </h5>
              <p>
                <strong>What did we do : </strong>Identified one of the leading influencer “Chanchal
                Chowdhury” to review Sepnil social activities and post videos on Facebook to create
                social awareness
              </p>
              <p>
                <strong>Results : </strong>Post Reach – 72%. Post Views – 52%. Post Engagement – 32%
              </p>
            </div>
          </div>
          <div id='sm-3' className='d-none'>
            <div className='case-study-img-sm'>
              <img src={caseStudyImages[2]} alt='' />
            </div>
            <div className='case-study-desc-sm'>
              <h3>Client : Meril Petroleum</h3>
              <h5>
                Objective : Increase product presence in the winter market and widen market share.
              </h5>
              <p>
                <strong>What did we do : </strong>Identified 03 top influencers to review Meril
                petroleum and post videos on Facebook &amp; Instagram to expand awareness. Also we
                distributed their own content in various social group.
              </p>
              <p>
                <strong>Results : </strong>Post Reach – 43%. Post Views – 71%. Post Engagement – 29%
              </p>
            </div>
          </div>
          <div id='sm-4' className='d-none'>
            <div className='case-study-img-sm'>
              <img src={caseStudyImages[3]} alt='' />
            </div>
            <div className='case-study-desc-sm'>
              <h3>Client : KOOL</h3>
              <h5>Objective : Increase awareness about KOOL in a COOL way.</h5>
              <p>
                <strong>What did we do : </strong>Identified 03 top influencers to review KOOL and
                post videos on Facebook &amp; Instagram to expand awareness in a cool way.
              </p>
              <p>
                <strong>Results : </strong>Post Reach – 31%. Post Views – 54%. Post Engagement – 26%
              </p>
            </div>
          </div>
          <div id='sm-5' className='d-none'>
            <div className='case-study-img-sm'>
              <img src={caseStudyImages[4]} alt='' />
            </div>
            <div className='case-study-desc-sm'>
              <h3>Client : Square Foods &amp; Beverage Limited</h3>
              <h5>
                Objective : Increase brand presence in Bangladeshi market through beautiful
                Bangladesh.
              </h5>
              <p>
                <strong>What did we do : </strong>Identified 6 top influencers to share SFBL #Amar
                Bangladesh content from their social media pages.
              </p>
              <p>
                <strong>Results : </strong>Post Reach – 76%. Post Views – 63%. Post Engagement – 42%
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* go to campaings */}
      <div className='view-campaigns'>
        <h5
          style={{ cursor: 'pointer', color: '#F2667A', textDecoration: 'underline' }}
          onClick={() => {
            redirectToCampaigns()
          }}>
          {' '}
          View more of our campaigns <DoubleArrowIcon />
        </h5>
      </div>
    </div>
  )
}

export default CaseStudy
