import React from 'react'

import whiteLogo from './logo.png'
import payment from './payment.png'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const year = new Date().getFullYear()
  const navigate = useNavigate()

  return (
    <div className='footer d-flex flex-column align-items-center justify-content-center '>
      <div className='container row row-cols-1 row-cols-md-2 row-cols-lg-3 d-flex justify-content-center align-items-center'>
        <div className='col d-flex flex-column flex-sm-row flex-md-column justify-content=center align-items-center'>
          <div
            onClick={() => {
              navigate('/')
            }}>
            <img src={whiteLogo} height='100px' alt='' />
          </div>
          <div>
            <p>
              <a
                href='mailto:contact@influencerhubbd.com'
                style={{ textDecoration: 'none', color: 'inherit' }}>
                contact@influencerhubbd.com
              </a>
            </p>
            <p className='footer-icons'>
              <a
                href='https://facebook.com/Influencerhub.bd'
                target='_blank'
                rel='noopener noreferrer'>
                <FontAwesomeIcon className='floating-social' icon={faFacebook} />
              </a>
              <a
                href='https://instagram.com/officialinfluencerhub'
                target='_blank'
                rel='noopener noreferrer'>
                <FontAwesomeIcon className='floating-social' icon={faInstagram} />
              </a>
              <a
                href='https://www.linkedin.com/company/influencerhub-bd/'
                target='_blank'
                rel='noopener noreferrer'>
                <FontAwesomeIcon className='floating-social' icon={faLinkedin} />
              </a>
            </p>
            <div className='px-2 mb-3'>
              <img src={payment} height='30px' alt='' />
            </div>
          </div>
        </div>
        <div className='col pt-2 pb-3 py-md-0 d-flex flex-column align-items-center'>
          <h4>Subscribe to our newsletter</h4>
          <div className='d-flex'>
            <input
              className='newsletter-input'
              type='email'
              name='email'
              id='email-newsletter'
              placeholder='Your Email'
            />{' '}
            <button className='newsletter-btn'>Submit</button>
          </div>
        </div>
        <div className='col text-start pt-3 '>
          <div className='d-flex justify-content-center'>
            <div className='loc'>
              <h5>Bangladesh</h5>
              <p>
                147/C, GREEN ROAD, <br />
                Tejgaon,
                <br />
                Dhaka, 1215
                <br />
                <br />
              </p>
              <p>
                S K M Asaduzzaman -{' '}
                <a href='tel:01915749299' style={{ textDecoration: 'none', color: 'inherit' }}>
                  01915749299
                </a>{' '}
                <br />
                Md. Arifur Rahman -{' '}
                <a href='tel:01720332031' style={{ textDecoration: 'none', color: 'inherit' }}>
                  01720332031
                </a>{' '}
                <br />
                <a
                  href='mailto:consultantdigitalhub@gmail.com'
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  consultantdigitalhub@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center pt-3'>
        <p>Copyright © {year} Influencer Hub</p>
        {/* <p className="mx-3">Privacy Policy</p>
        <p>Terms and Conditions</p> */}
      </div>
    </div>
  )
}

export default Footer
