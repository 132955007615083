import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

const Brands = () => {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    fetch("https://young-hollows-72882.herokuapp.com/brands")
      .then((res) => res.json())
      .then((data) => setBrands(data));
  }, []);

  console.log(brands);

  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "75%",
      sm: "75%",
      md: "50%",
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: 15,
    p: 4,
    overflowY: "auto",
  };

  return (
    <div>
      <h1>Brands {brands.length}</h1>
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Budget</th>
              <th scope="col">Contact</th>
              <th scope="col">View Details</th>
              {/* <th scope="col">Contact</th> */}
              {/* <th scope="col">Email</th> */}
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <tr key={brand}>
                <td>{brand.name}</td>
                <td>{brand.budget}</td>
                <td>{brand.contact}</td>
                <td>
                  <button onClick={handleOpen}>Details</button>
                </td>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div>
                      <p>Name : {brand.name}</p>
                      <p>Message : {brand.message}</p>
                      <p>Email : {brand.email}</p>
                      <p>Contact : {brand.contact}</p>
                      <p>Objective : {brand.objective}</p>
                      <p>Profile : {brand.profile}</p>
                      <p>Type of Posts : {brand.posts}</p>
                      <p>Type of Audience : {brand.audience}</p>
                      <p>Type of Influencers : {brand.influencerType}</p>
                      <p>Target Country : {brand.country}</p>
                      <p>Target Platform : {brand.platform}</p>
                      <p>Campaign Duration : {brand.duration}</p>
                      <p>Budget : {brand.budget}</p>
                    </div>
                  </Box>
                </Modal>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Brands;
