import "./InfluencerRegister.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";

import React from "react";

const InfluencerRegister = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const prepData = (data) => {
    const formData = new FormData();
    // text data
    formData.append("name", data.name);
    formData.append("location", data.location);
    formData.append("contact", data.contact);
    formData.append("email", data.email);
    formData.append("followers", data.followers.value);
    formData.append("instagramLink", data.instagramLink);
    formData.append("snapChatLink", data.snapchatLink);
    formData.append("tiktokLink", data.tiktokLink);
    formData.append("youtubeLink", data.youtubeLink);
    // files
    formData.append("instagramInsight", data.instagramInsight[0]);
    formData.append("snapchatInsight", data.snapchatInsight[0]);
    formData.append("tiktokInsight", data.tiktokInsight[0]);
    formData.append("youtubeInsight", data.youtubeInsight[0]);

    return formData;
  };
  const onSubmit = (data) => {
    data = prepData(data);
    axios
      .post("https://young-hollows-72882.herokuapp.com/influencers", data)
      .then((data) => {
        if (data.insertedId) {
          console.log("success");
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="influencer-register text-center">
      <div className="inf-heading ">
        <div className="container d-flex flex-column align-items-center py-4">
          <h2>Influencers of Influencer Hub</h2>
          <p className="w-50">
            We are always growing our family of Influencers. If you have what it
            takes and you have a audience; register below
          </p>
        </div>
      </div>

      <form
        className="container inf-reg-form text-start"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div>
          <h3>Personal Details:</h3>
          <div className="form-row">
            <div className="inp-container">
              <input
                type="text"
                placeholder="Name"
                {...register("name", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
            </div>
            <div className="inp-container">
              <input
                type="text"
                placeholder="Country/City"
                {...register("location", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.location && (
                <p className="text-danger">{errors.location.message}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="inp-container">
              <input
                type="text"
                placeholder="Contact Number"
                {...register("contact", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.contact && (
                <p className="text-danger">{errors.contact.message}</p>
              )}
            </div>
            <div className="inp-container">
              <input
                type="email"
                name=""
                id=""
                placeholder="Email"
                {...register("email", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </div>
          </div>
        </div>
        <div>
          <h3>Social Media Info:</h3>
          <div className="form-row justify-content-start">
            <div className="inp-container">
              <Controller
                name="followers"
                control={control}
                rules={{ required: "One option has to be selected." }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: "10K+", label: "Above 10K" },
                      { value: "50K+", label: "Above 50K" },
                      { value: "100K+", label: "Above 100K" },
                      { value: "500K+", label: "Above 500K" },
                      { value: "1M+", label: "Above 1M" },
                    ]}
                    placeholder="Number of Followers"
                  />
                )}
              />
              {errors.followers && (
                <p className="text-danger">{errors.followers.message}</p>
              )}
            </div>
          </div>
          <h5> Social Media Links: </h5>
          <div className="form-row">
            <div className="inp-container">
              <input
                type="text"
                placeholder="Instagram Link"
                {...register("instagramLink", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.instagramLink && (
                <p className="text-danger">{errors.instagramLink.message}</p>
              )}
            </div>
            <div className="inp-container">
              <input
                type="text"
                placeholder="Snapchat Link"
                {...register("snapChatLink", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.snapchatLink && (
                <p className="text-danger">{errors.snapchatLink.message}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="inp-container">
              <input
                type="text"
                placeholder="Tiktok Link"
                {...register("tiktokLink", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.tiktokLink && (
                <p className="text-danger">{errors.tiktokLink.message}</p>
              )}
            </div>
            <div className="inp-container">
              <input
                type="text"
                placeholder="YouTube Link"
                {...register("youtubeLink", {
                  required: "This field must not be left empty.",
                })}
              />
              {errors.youtubeLink && (
                <p className="text-danger">{errors.youtubeLink.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="insights">
          <h3>Accounts Insights:</h3>
          <div className="form-row align-items-center">
            <div className="inp-container">
              {/* insta */}
              <label htmlFor="insta-ins">
                Upload your Instagram accounts insights (JPEG format)
              </label>
            </div>
            <div className="inp-container">
              <input
                id="insta-ins"
                type="file"
                accept=".jpg, .jpeg, .png"
                {...register("instagramInsight", {
                  required: "This field must not be left empty.",
                })}
              ></input>
              {errors.instagramInsight && (
                <p className="text-danger">{errors.instagramInsight.message}</p>
              )}
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="inp-container">
              {/* snap */}
              <label htmlFor="snap-ins">
                Upload your Snapchat accounts insights (JPEG format)
              </label>
            </div>
            <div className="inp-container">
              <input
                id="snap-ins"
                type="file"
                accept=".jpg, .jpeg, .png"
                {...register("snapchatInsight", {
                  required: "This field must not be left empty.",
                })}
              ></input>
              {errors.snapchatInsight && (
                <p className="text-danger">{errors.snapchatInsight.message}</p>
              )}
            </div>
          </div>
          {/* tiktok */}
          <div className="form-row align-items-center">
            <div className="inp-container">
              <label htmlFor="snap-ins">
                Upload your TikTok accounts insights (JPEG format)
              </label>
            </div>
            <div className="inp-container">
              <input
                id="tiktok-ins"
                type="file"
                accept=".jpg, .jpeg, .png"
                {...register("tiktokInsight", {
                  required: "This field must not be left empty.",
                })}
              ></input>
              {errors.tiktokInsight && (
                <p className="text-danger">{errors.tiktokInsight.message}</p>
              )}
            </div>
          </div>
          {/* yt */}
          <div className="form-row align-items-center">
            <div className="inp-container">
              <label htmlFor="yt-ins">
                Upload your YouTube accounts insights (JPEG format)
              </label>
            </div>
            <div className="inp-container">
              <input
                id="yt-ins"
                type="file"
                accept=".jpg, .jpeg, .png"
                {...register("youtubeInsight", {
                  required: "This field must not be left empty.",
                })}
              ></input>
              {errors.youtubeInsight && (
                <p className="text-danger">{errors.youtubeInsight.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button className="btn-generic">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default InfluencerRegister;
