import React, { useEffect, useState, useRef } from 'react'
import blackLogo from './logo-black.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { useNavigate, NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

import './Header.css'
import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import { useForm } from 'react-hook-form'
import axios from 'axios'

const Header = (props) => {
  // date
  const year = new Date().getFullYear()
  // user state
  const { loginStatus, setLoginStatus } = props
  // states
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  // show login error
  const [error, setError] = useState('')

  const header = useRef()
  const navLogo = useRef()
  const btnScroll = useRef()
  const navigate = useNavigate()

  // on login submit
  const onSubmit = (data) => {
    // console.log(data);
    axios.post('https://young-hollows-72882.herokuapp.com/admins', data).then((res) => {
      if (res.data === true) {
        setLoginStatus(true)
        setOpen(false)
      } else if (res.data === false) {
        setError('Invalid Credentials.')
      }
      reset()
    })
  }

  // scroll to top function
  const scrollToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  // redir to route function
  const redirectToRoute = (route) => {
    navigate(route)
  }

  // modal state
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  // modal style
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '75%',
      sm: '75%',
      md: '50%',
    },
    bgcolor: '#e04b4b',
    borderRadius: '15px',
    boxShadow: 15,
    px: 4,
    py: 8,
  }

  // nav dropdown on scroll and scroll to top button
  const scrollEffect = () => {
    const navBar = header.current
    if (btnScroll.current !== null && navLogo.current !== null) {
      btnScroll.current.style.bottom = '-20%'
      if (window.scrollY > 40) {
        navBar.classList.remove('header')
        navBar.classList.add('animate')
        navLogo.current.style.height = '70px'
        btnScroll.current.style.bottom = '10px'
      } else if (!window.scrollY) {
        navBar.classList.remove('animate')
        navBar.classList.add('header')
        navLogo.current.style.height = '85px'
        btnScroll.current.style.bottom = '-20%'
      }
    }
  }
  useEffect(() => {
    if (header.current !== null) {
      document.addEventListener('scroll', scrollEffect)
    }

    return () => {
      document.removeEventListener('scroll', scrollEffect)
    }
  }, [])

  const toggleNavModal = () => {
    setShowModal(!showModal)
  }
  // toggle Nav Modal
  useEffect(() => {
    const navModal = document.getElementById('nav-modal')
    showModal ? (navModal.style.left = 0) : (navModal.style.left = '-100%')
    if (showModal) {
      document.addEventListener('click', (e) => {
        if (
          e.target.id !== 'nav-modal' &&
          e.target.id !== 'nav-btn' &&
          e.target.id !== 'nav-icon' &&
          e.target.parentNode.id !== 'header' &&
          e.target.parentNode.id !== 'nav-icon'
        )
          setShowModal(false)
      })
    }
  }, [showModal])

  return (
    <div ref={header} className='header fixed-top'>
      <div className='container d-flex justify-content-between align-items-center py-2'>
        <div className='nav-logo'>
          <img
            ref={navLogo}
            src={blackLogo}
            height='85px'
            alt=''
            onClick={() => {
              redirectToRoute('/home')
            }}
          />
        </div>
        <div className='d-none d-lg-block'>
          <ul className='nav-md'>
            <NavLink to='/services' className='nav-link-md'>
              Services
            </NavLink>
            <NavLink
              to='campaigns'
              className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
              Campaigns
            </NavLink>
            <NavLink
              to='brands'
              className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
              Clients
            </NavLink>
            <NavHashLink
              to='influencers'
              className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
              Influencers
            </NavHashLink>
            <NavHashLink to='/home#contact' className='nav-link-md'>
              Contact
            </NavHashLink>
          </ul>
        </div>
        <div className='login-hamburger'>
          <div>
            {!loginStatus && (
              <span className='me-sm-2 nav-link-md' onClick={handleOpen}>
                Login
              </span>
            )}
            {loginStatus && (
              <div>
                <span
                  className='me-2 nav-link-md'
                  onClick={() => {
                    redirectToRoute('/dashboard')
                  }}>
                  Dashboard
                </span>
                <span
                  className='me-2 nav-link-md'
                  onClick={() => {
                    setLoginStatus(false)
                  }}>
                  Logout
                </span>
              </div>
            )}
          </div>
          <button
            className='d-block d-lg-none'
            id='nav-btn'
            onClick={() => {
              toggleNavModal()
            }}>
            <FontAwesomeIcon id='nav-icon' icon={faBars} />
          </button>
        </div>
      </div>
      {/* login modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={modalStyle}>
          <div className='text-center'>
            <h3 className='mb-4 text-light fw-bold'>Admin Login</h3>
            <form onSubmit={handleSubmit(onSubmit)} className='login-form'>
              <input type='email' placeholder='Email' {...register('email')} />
              <input type='password' placeholder='Password' {...register('password')} />
              <button className='btn-generic'> Submit </button>
            </form>
            {error && <p>{error}</p>}
          </div>
        </Box>
      </Modal>

      {/* nav-modal */}
      <div id='nav-modal'>
        <NavLink to='/services' className='nav-link-md'>
          Services
        </NavLink>
        <NavLink
          to='campaigns'
          className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
          Campaigns
        </NavLink>
        <NavLink
          to='brands'
          className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
          Clients
        </NavLink>
        <NavHashLink
          to='influencers'
          className={({ isActive }) => (isActive ? 'nav-link-md-active' : 'nav-link-md')}>
          Influencers
        </NavHashLink>
        <NavHashLink to='/home#contact' className='nav-link-md'>
          Contact
        </NavHashLink>
        <div className='header-social'>
          <a href='https://facebook.com/Influencerhub.bd' target='_blank' rel='noopener noreferrer'>
            <FontAwesomeIcon className='floating-social' icon={faFacebook} />
          </a>
          <a
            href='https://instagram.com/officialinfluencerhub'
            target='_blank'
            rel='noopener noreferrer'>
            <FontAwesomeIcon className='floating-social' icon={faInstagram} />
          </a>
          <a
            href='https://www.linkedin.com/company/influencerhub-bd/'
            target='_blank'
            rel='noopener noreferrer'>
            <FontAwesomeIcon className='floating-social' icon={faLinkedin} />
          </a>
        </div>
        <p className='pt-4'>
          <small>Copyright © {year} Influencer Hub</small>
        </p>
      </div>

      {/* scroll to top */}
      <div ref={btnScroll} className='btn-scroll'>
        <button
          onClick={() => {
            scrollToTop()
          }}>
          <FontAwesomeIcon icon={faCaretUp}> </FontAwesomeIcon>
        </button>
      </div>
    </div>
  )
}

export default Header
