import React, { useState, useEffect } from "react"
import "./Influencers.css"
import InfluencersMD from "./InfluencersMD/InfluencersMD"
import InfluencersSM from "./InfluencersSM/InfluencersSM"

import { infImages } from "../../../utils/Images"

const Influencers = () => {
  const [icons, setIcons] = useState([])

  useEffect(() => {
    fetch("./category-icons.json")
      .then((res) => res.json())
      .then((data) => setIcons(data))

    return () => {
      setIcons([])
    }
  }, [])

  const images = infImages
  return (
    <div id="influencers">
      <InfluencersMD images={images} />
      <InfluencersSM images={images} />

      {/* icons */}
      <div className="category-icons">
        {icons.map((icon) => (
          <div className="d-flex  justify-content-center align-items-center mx-2" key={icon.id}>
            <img className="img-fluid" src={icon.img} alt="icon-img" />
            <span className="mx-1 icon-title">{icon.title}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Influencers
