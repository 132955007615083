import React from "react"
import "./NotFound.css"

const NotFound = () => {
  return (
    <div className="not-found">
      <p className="not-found-text">Not Found</p>
    </div>
  )
}

export default NotFound
