import React from "react"
import "./About.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

const About = () => {
  return (
    <div id="about" className="container pt-5">
      <div className="about">
        <h1 className="text-center mb-2">About us</h1>
        <div className="about-desc container">
          <p>Influencer Hub is the country's first data-driven influencer marketing agency, running creative influencer marketing campaigns for the country's leading companies. We launch influencer campaigns on a large scale due to our strong relationships with the majority of the influencers. Influencer Hub has a distinct data-driven strategy as well as a patented real-time tracking technology. We also have specialists who will create unique marketing tactics with long-term value to fulfill your company's ongoing demand.</p>
          <p className="about-contact d-flex align-items-center">
            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            <a className="ms-2" href="mailto:contact@influencerhubbd.com">
              contact@influencerhubbd.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
